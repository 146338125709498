$ui-icon-loader-mask-color-main: rgba(0, 0, 0, 0);
$ui-icon-loader-mask-background-color: rgba(169, 169, 169, .25);
$ui-icon-loader-mask-color-secondary: rgba(33, 135, 231, 0);
$ui-icon-loader-mask-border-color-main: rgba(0, 183, 229, .9);
$ui-icon-loader-mask-border-color-secondary: rgba(0, 183, 229, .9);

.loader-mask-icon {
    position: absolute;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $ui-icon-loader-mask-background-color;
    opacity: .4;

    &.fixed {
        position: fixed;
    }

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        border: 5px solid $ui-icon-loader-mask-border-color-main;
        border-radius: 50px;
        border-right-color: $ui-icon-loader-mask-color-main;
        border-left-color: $ui-icon-loader-mask-color-main;

        margin: -25px 0 0 -25px;
        animation: spinPulse 1s infinite ease-in-out;
        background-color: $ui-icon-loader-mask-color-main;
        box-shadow: 0 0 35px $ui-icon-loader-mask-color-secondary;
        opacity: .9;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 30px;
        border: 5px solid $ui-icon-loader-mask-border-color-secondary;
        border-radius: 50px;
        border-right-color: $ui-icon-loader-mask-color-main;
        border-left-color: $ui-icon-loader-mask-color-main;

        margin: -15px 0 0 -15px;
        animation: spinOffPulse 1s infinite linear;
        background-color: $ui-icon-loader-mask-color-main;
        box-shadow: 0 0 15px $ui-icon-loader-mask-color-secondary;
        opacity: .9;
    }
}

@keyframes spinPulse {
    0% {
        box-shadow: 0 0 1px $ui-icon-loader-mask-color-secondary;
        opacity: 0;
        transform: rotate(160deg);
    }

    50% {
        opacity: 1;
        transform: rotate(145deg);
    }

    100% {
        opacity: 0;
        transform: rotate(-320deg);
    }
}
@keyframes spinOffPulse {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
