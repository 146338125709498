.embla {
    position: relative;
    padding: $embla-slider__container-padding;
    background-color: $embla-slider__container-background-color;

    &__viewport {
        overflow: hidden;
        width: 100%;

        &.is-draggable {
            cursor: move;
        }

        &.is-dragging {
            cursor: grabbing;
        }
    }

    &__container {
        display: flex;
        -webkit-touch-callout: none;
        user-select: none;
    }

    &__slide {
        position: relative;
        min-width: calc(100%/3); // Should be changed for target sliders.
        padding: 0 5px;
    }

    &__slide__inner {
        position: relative;
        overflow: hidden;
    }

    &__slide__img {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: auto;
        min-width: 100%;
        max-width: none;
        min-height: 100%;
        transform: translate(-50%, -50%);
    }

    &__button {
        position: absolute;
        z-index: 1;
        top: 50%;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        fill: $embla-slider__nav-button-color;
        outline: 0;
        touch-action: manipulation;
        transform: translateY(-50%);
    }

    &__button:disabled {
        cursor: default;
        opacity: 0.3;
    }


    &__button__svg {
        width: 100%;
        height: 100%;
    }

    &__button {
        &--prev {
            left: 27px;
        }

        &--next {
            right: 27px;
        }
    }
}
