[x-cloak] {
    display: none !important; // eslint-disable-line
}

@media screen and (max-width: 768px) {
    [x-cloak='mobile'] { display: none; }
}

[x-dev-control] {
    background-color: $color-dark-gray;
}
