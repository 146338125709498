// Embla slider - START
$embla-slider__container-background-color: transparent;
$embla-slider__container-padding: 1rem 8rem;
$embla-slider__nav-button-color: #1bcacd;
$color-dark-gray: #797c7e !default;
// Embla slider - END

// Skeleton loader - START
$ph-direction: ltr !default;
$ph-bg: #fff !default;
$ph-letter-spacing: 3px !default;
$ph-color: #ced4da !default;
$ph-border: none !default; // 1px solid scale-color($ph-bg, $lightness: -10%) !default;
$ph-border-radius: 2px !default;

$ph-cols: 12 !default;
$ph-cols-remove-odd: false !default;
$ph-gutter: 30px !default;
$ph-spacer: 15px !default;

$ph-avatar-border-radius: 50% !default;

$ph-animation-duration: 1.3s !default;
// Skeleton loader - END
