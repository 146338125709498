// stylelint-disable

// TODO Breakpoints implementation @see https://www.embla-carousel.com/guides/breakpoints/

// README NOTES:
// The next styles are considered for the full rewriting.
// They are created for backend development purpose.
// The styles for the widget should be defined in the current file (additional files
// can be created within the current directory).
// Site specific difference should be handled in an appropriate site cartridge.
// Current file defines base styles shared across all sites.

.klarna-upsell-listing-header,
.klarna-upsell-listing-footer,
.klarna-upsell-listing-confirmation,
.klarna-upsell-listing-exception,
.klarna-upsell-listing-timeout {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    line-height: 70px;
    color: #000000;
    text-align: center;
}

.klarna-upsell-listing-header h1,
.klarna-upsell-listing-footer h1,
.klarna-upsell-listing-confirmation h1,
.klarna-upsell-listing-exception h1,
.klarna-upsell-listing-timeout h1 {
    font-size: 3.5rem;
    font-size: 3.5rem;
    padding-bottom: 20px;
}

.klarna-upsell-container,
.klarna-upsell-listing-footer {
    padding: 16px;
}

.klarna-upsell-listing-footer,
.klarna-upsell-listing-header,
.klarna-upsell-listing-confirmation,
.klarna-upsell-listing-exception,
.klarna-upsell-listing-timeout,
.klarna-upsell-listing-confirmation p,
.klarna-upsell-listing-exception p,
.klarna-upsell-listing-timeout p,
.klarna-upsell-listing-footer p,
.klarna-upsell-listing-header p {
    font-size: 1.9rem;
    max-width: 800px;
    margin: 15px auto;
}

#klarna-upsell__timer {
    padding: 0 2px;
}

.klarna-upsell-widget {
    .add-to-cart.selected {
        color: transparent; // Hide title on button to replace it with image.
        background-image: url('../images/klarna-upsell-selected-item-icon.svg');
        background-position: center;
        background-repeat: no-repeat;

        &:hover {
            color: transparent;
        }
    }
}

.klarna-upsell-container .grid-item {
    .form-group {
        height: 10px;
    }
}
